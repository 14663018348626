// detecting IE
function isIE() {
  var myNav = navigator.userAgent.toLowerCase();
  return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
}

var d = document;
var b = d.body;
var ieVersion = null;

// Test IE10 properties
if (document.body.style.msFlexOrder !== undefined) {
  ieVersion = "ie10";
}
// Test IE11 propertie
if (document.body.style.msTextCombineHorizontal !== undefined) {
  ieVersion = "ie11";
}
$(document).ready(function () {

  // remove Bedankt links from sitemap
  $('.sitemap a[title="Bedankt!"]').parent().remove();


  // Align div.small.box
  (function () {
    var $smallBox, $wrapper, rowIndex;
    $smallBox = $('div.small.box.tmd-whitepaper');
    if ($smallBox.size() === 0)
      return;
    $('header.article-header:first').after($smallBox);
    $smallBox.css('float', 'right');
    $smallBox.find('input[type=submit]').addClass('fixed button');
    $smallBox.find('input[type=checkbox]').closest('label').addClass('checkbox').css('fontWeight', 'normal');
  })();

  $('ul.toggleable').on('click', 'li > a', function (e) {
    e.preventDefault();
    e.stopPropagation();
    var _this = $(this).parent();
    var container = _this.closest('ul.toggleable');

    container.children('li.active').removeClass('active');
    _this.toggleClass('active');
  });

  $('.box.linked').on('click', function (e) {
    e.stopPropagation();
    e.preventDefault();
    var href = $(this).find('a[data-link]').attr('href');

    if (typeof href != 'undefined') {
      window.location = href;
    } else
      console.error('No a[data-link] was defined for this .box');
  });

  $('.box.linked').on('click', 'a', function (e) {
    e.stopPropagation();
  });

  $('label.checkbox').on('click', function (e) {
    e.preventDefault();
    var _this = $(this);
    var checkbox = _this.children('input[type="checkbox"]');

    _this.toggleClass('checked');
    if (_this.hasClass('checked')) {
      checkbox.prop('checked', true);
    } else {
      checkbox.prop('checked', false);
    }
  });

  $('#search-toggle').on('click', function (e) {
    e.preventDefault();

    $(this).parent().addClass('active');
    $(this).siblings('form').children('input[type="text"]').focus();
  });

  $('#search input[type="text"]').on('blur', function () {
    $('#search').delay(100).queue(function () {
      $(this).removeClass('active');
      $(this).dequeue();
    });
  });

  $('#search input[type="submit"]').on('click', function () {
    $('#search').clearQueue();
  });

  $('#featured li .content[data-link]').on('click', function (e) {
    e.preventDefault();

    window.location = $(this).attr('data-link');
  });
  
//Open Menu
  var page_height = $(document).height();
  $('.dark-overlay').css('height', page_height + 'px');
  $(document).on('click', 'a.menu-link', function(event){
    event.preventDefault();
    $('.dark-overlay').fadeIn(200);
    $('.dark-menu').animate({ right:'0' },200);
  });
  $(document).on('click', '.dark-overlay, .close-dark-menu', function(){
    $('.dark-menu').animate({ right:'-350px' },200);
    $('.dark-overlay').fadeOut(200);
  });

// fix logo images in IE
  if (isIE() || ieVersion) {
    if ($('body').hasClass('page-template-page-oplossingen')) {

      var etaW = $('#inner-content .row:eq(1) .columns:eq(1) .box').width();
      $('#inner-content .vertical-center img').each(function () {
        var $this = $(this);
        var imageW = $(this).outerWidth();
        if (parseInt(imageW) > parseInt(etaW)) {
          var amountToSet = parseInt(etaW) - 100;
          var strngToSet = 'width: ' + amountToSet + 'px';
          $this.attr('style', strngToSet);
        }
      });
    }

  }


  $(window).resize();

});

$(window).load(function () {

  var loaded = 0;
  var imgs = $('#introduction ul.logos li img');
  var imgCount = imgs.length;

  imgs.each(function () {
    var imgLoad = new Image();
    imgLoad.onload = function () {
      loaded++;
    };
    imgLoad.src = $(this).attr('src');
  });

  var interval = setInterval((function () {

    if (loaded != imgCount)
      return;
    clearInterval(interval);
    $('body').removeClass('loading');

    if ($('#introduction ul.logos').length > 0) {
      w = 0;

      $('ul.logos li').each(function () {
        w += $(this).outerWidth(true);
      });

      $('#introduction ul.logos').width(w).css({'left': -(w - $('#introduction ul.logos').parent().width())});
    }

    $(window).resize();

  }).bind(this), 100);

});

$(window).resize(function () {

  $('*[data-equal-height]').each(function () {
    var _this = $(this);
    var selector = _this.attr('data-equal-height');
    var elements = _this.find(selector);
    var height = 0;

    elements.height('auto');
    elements.each(function () {
      if ($(this).height() > height)
        height = $(this).height();
    });

    elements.height(height);
  });

  $('#menu-hoofdnavigatie li ul').each(function () {
    var _this = $(this);
    var elements = _this.children('li');
    var height = 0;

    elements.height('auto');
    elements.each(function () {
      if ($(this).height() > height)
        height = $(this).height();
    });

    elements.height(height);
  });
  //Menu dark overlay resize
  var page_height = $(document).height();
  $('.dark-overlay').css('height', page_height + 'px');
});

var lastScrollTop = 0;
$(window).scroll(function (event) {
  var isIE11 = !!(navigator.userAgent.match(/Trident/) && (navigator.userAgent.match(/rv 11/) || (navigator.userAgent.match(/rv:11/))));
  var isIE = (navigator.userAgent.match(/MSIE/) || isIE11) ? true : false;

  if (!isIE) {
    var st = $(this).scrollTop();
    if (st > lastScrollTop) {
      if (st > 100)
        $('#header > .fixed').addClass('scrolldown');
    } else {
      $('#header > .fixed').removeClass('scrolldown');
    }
    lastScrollTop = st;

    $('.header').redraw();
  }
});

jQuery.fn.redraw = function () {
  return this.hide(0, function () {
    $(this).show(0);
  });
};